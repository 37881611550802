.App {
  text-align: center;
}

.memeSelect{
  height: 100%;
  max-width: 100%;

}

.memeSelectButton{
  height: 100%;
  max-width: 100px;
 text-decoration: none;
}

.memeChooser{
  display: flex;
  overflow-x: auto;
  gap: 10px;
  height: 80px;
  background-color: rgba(156, 54, 54, 0.5);
  justify-content:center;
}

.grid{
  display: grid;
  grid-template-columns: 15% 85%;
  height: 100vh;
}


.nav{
  display: grid;
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
}

.content{
  display: grid;
  text-align: center;
  width: 100%;
  grid-template-rows: 10% 10% 10% ;
}


.navitem{
  width: 100%;
  display: block;
  height: 50px;
  text-align: center;
  text-decoration: none;
  font-size: 1.5em;
}


.active,  navitem {
  display: block;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  text-decoration: none;
  border-radius: 10px;
}

.pending,  navitem {
  text-decoration: none;
}
a:hover{
  display: block;
  background-color: rgba(223, 73, 53, 0.5);
  width: 100%;
  height: 100%;
  text-decoration: none;
  border-radius: 10px; 
}

/* CardGnerator */

.cardInput{
  display: grid;
}

.cardInputRow{
  display: grid;
  text-align: left;
  grid-template-columns: 30% 70%;
}

.cardContainer{
  width: 100%;
  height: 100%;
  display: grid;
  padding-left: 50px;
  grid-template-columns: 40% 60%;
  gap:80px;  

}

.cardBody{
  width: 600px;
  height: 800px;
  display: block;
  background-color: rgb(61, 59, 59);
  border-radius: 20px;
  padding-top: 25px;
  padding-left: 30px;
}

.cardDesign{
  width: 570px;
  height: 770px;
  display: block;
  background-color: rgba(156, 54, 54, 1);
  border-radius: 15px;
}

.cardLayout{
  display: grid;
  grid-template-rows: 50px 350px 352px;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  gap: 10px;
}

.cardTitle {
display: grid;
grid-template-columns: 70% 30%;
}


#cardName{
  text-align: left;
  font-size: 1.7em;
}

#cardPower{
  text-align: right;
  font-size: 1.5em;
  padding: 20px;
}

.cardType{
  text-align: right;
}
.cardImageContainer{
  height: 100%;
  display: block;
  background-color: rgba(226, 207, 207, 0.336);
  border-radius: 30px;
  box-shadow: 5px 5px 5px rgb(167, 144, 144);

}

.cardImage{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;

}

.cardText{
  height: 100%;
display: grid;
grid-template-rows:0.2fr 2fr 1fr 1fr 1fr;
text-align: left;
gap:5px;
font-size: 1.1em;
}

.cardTextEntry{

display: grid;
grid-template-columns: 50% 50%;
/* border-bottom: solid; */
}


.cardTextEntryItem{
  text-align: left;
  font-size: 1.3em;

}

.cardTextEntryValue{
  text-align: right;
}
